import React, { useEffect, useState } from "react";
import {
  Pane,
  Button,
  Text,
  TextInputField,
  toaster,
  Checkbox,
  Switch,
} from "evergreen-ui";
import axios from "axios";
import "./Settings.css";
import { readStorage, apiEndPoint } from "../../utils";
function Settings({ selectedTab, setSelectedTab }) {
  const token = localStorage.getItem("jwt");
  const [loading, setLoading] = useState(true);
  const [mySettings, setMySettings] = useState({
    position: "",
    promotion: "",
    birthday: "",
    workAnniversary: "",
    sendBirthdayMail: false,
    sendWorkAnniversaryMail: false,
    sendJobChangeMail: false,
    isEmailActive: false,
  });


  function handleChange(e) {
    const { name, value } = e.target;
    setMySettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  async function handleUpdateMessages() {
    //setLoading(true);
    if (
      !mySettings.position ||
      !mySettings.promotion ||
      !mySettings.birthday ||
      !mySettings.workAnniversary
    ) {
      toaster.danger("All fields are required!", { duration: 6 });
      return;
    }

    setLoading(true);
    try {
      const { data } = await axios.patch(
        apiEndPoint + `/notifications/update-message-settings`,
        mySettings,
        { headers: { authorization: "Bearer " + token } }
      );
      setLoading(false);
      if (data.success) {
        toaster.success("Your default messages have been updated!", {
          duration: 6,
        });
        return;
      }

      toaster.danger(data.message, { duration: 6 });
    } catch (e) {
      if (e.response) {
        toaster.danger(e.response.data.message);
      }
      setLoading(false);
    }
  }
  async function loadSettings() {
    const { data } = await axios.get(
      apiEndPoint + `/notifications/get-message-settings`,
      { headers: { authorization: "Bearer " + token } }
    );
    setLoading(false);
    if (data.success) {
      setMySettings((prev) => ({
        ...prev,
        ...data.data,
      }));
      // toaster.success('Your password has been updated!', { duration: 6 });
      return;
    }

    // toaster.danger(data.message, { duration: 6 });
  }
  useEffect(() => {
    setLoading(false);
    loadSettings();
  }, []);
  return (
    <Pane
      display="grid"
      justifyItems="center"
      textAlign="center"
      gridGap={20}
      placeContent="center"
      height="70vh"
    >
      <Text size={600} width={600}></Text>
      <Pane width="80%" display="block" textAlign="left">
        <h2 className="heading">Customize your Default Messages</h2>
        <TextInputField
          label="New Position"
          name="position"
          value={mySettings.position}
          placeholder=""
          type="text"
          onChange={handleChange}
        />
        <TextInputField
          label="Promotion"
          name="promotion"
          value={mySettings.promotion}
          type="text"
          placeholder=""
          onChange={handleChange}
        />
        <TextInputField
          label="Birthday"
          name="birthday"
          value={mySettings.birthday}
          type="text"
          placeholder=""
          onChange={handleChange}
        />
        <TextInputField
          label="Work Anniversary"
          name="workAnniversary"
          value={mySettings.workAnniversary}
          type="text"
          placeholder=""
          onChange={handleChange}
        />
        <h2 className="heading">Email Notifications</h2>
        <div style={{ fontSize: "15px" }}>
          <Pane
            style={{
              marginBottom: "15px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Switch
              checked={mySettings.isEmailActive}
              onChange={(e) =>
                setMySettings((prev) => ({
                  ...prev,
                  isEmailActive: e.target.checked,
                }))
              }
            />
            <label>Enable/Disable</label>
          </Pane>

          <Pane>
            <label>
              <input
                type="checkbox"
                checked={mySettings.sendWorkAnniversaryMail}
                onChange={(e) =>
                  setMySettings((prev) => ({
                    ...prev,
                    sendWorkAnniversaryMail: e.target.checked,
                  }))
                }
              />{" "}
              Anniversaries
            </label>
          </Pane>

          <Pane>
            <label>
              <input
                type="checkbox"
                checked={mySettings.sendBirthdayMail}
                onChange={(e) =>
                  setMySettings((prev) => ({
                    ...prev,
                    sendBirthdayMail: e.target.checked,
                  }))
                }
              />{" "}
              Birthdays
            </label>
          </Pane>

          <Pane>
            <label>
              <input
                type="checkbox"
                checked={mySettings.sendJobChangeMail}
                onChange={(e) =>
                  setMySettings((prev) => ({
                    ...prev,
                    sendJobChangeMail: e.target.checked,
                  }))
                }
              />{" "}
              Job Changes
            </label>
          </Pane>
        </div>
        <Button
          appearance="default"
          position="relative"
          left="60%"
          size="large"
          transform="translateX(-50%)"
          onClick={() => setSelectedTab("Welcome")}
        >
          Cancel
        </Button>
        <Button
          appearance="primary"
          position="relative"
          left="64%"
          size="large"
          transform="translateX(-50%)"
          onClick={handleUpdateMessages}
          isLoading={loading}
        >
          Update
        </Button>
      </Pane>
    </Pane>
  );
}
export default Settings;
