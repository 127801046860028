// src/hooks/useAuth.js
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

export function useAuth() {
  // Initialize based on whether a JWT exists in cookies
  const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get("jwt"));

  useEffect(() => {
    // Set authentication status based on the presence of "jwt" in cookies
    const authStatus = !!Cookies.get("jwt");
    setIsAuthenticated(authStatus);
  }, []);

  return isAuthenticated;
}
