import { config } from './config/environment';



// Export with uppercase 'P' to match existing usage
export const apiEndPoint = config.apiEndpoint;
export const stripePublishableKey = config.stripePublishableKey;

// Rest of your utility functions
export function formatTimeStamp(timestamp) {
  if (!timestamp) {
    return '';
  }
  const dateObj = new Date(timestamp);
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];
  let date = dateObj.getDate();
  if (date < 10) date = '0' + date;
  let month = dateObj.getMonth() + 1;
  if (month < 10) month = '0' + month;
  const year = dateObj.getFullYear().toString();
  let min = dateObj.getMinutes();
  let hour = dateObj.getHours();
  const sec = dateObj.getSeconds();
  if (min < 10) min = '0' + min;
  if (hour < 10) hour = '0' + hour;
  return {
    date2: `${dateObj.getFullYear().toString()}${month}${date}`,
    date: `${month}/${date}/${year}`,
    time: `${hour}:${min}`,
    meridiem: dateObj.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }),
  };
}

// Text and link handling utilities
export function replaceTextToLink(text = '') {
  const exp = /(\b(http|ftp|https):\/\/([\w-]+\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?)/gi;
  return text.replace(exp, "<a target='_blank' href='$1'>$1</a>");
}

export function limeTextConvertor(text = '') {
  return text
    .replaceAll(/&#92;/g, '\\')
    .replace(/\\u[\dA-F]{4}/gi, (match) =>
      String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
    );
}

// Storage utility (commented chrome-specific code preserved)
export function readStorage(key = 'token', method = 'sync') {
  return new Promise((resolve) => {
    // if (key) chrome.storage[method].get(key, (resp) => resolve(resp[key]));
    // else chrome.storage[method].get((resp) => resolve(resp[key]));
  });
}

// HTML and string utilities
export function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

export function cleanStr(name = '') {
  name = htmlDecode(name);
  return name
    .replace(
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|\!|\"|\·|\$|\%|\&|\/|\(|\)|\)|\=|\?|\¿|\'|\¡|\^|\*|\:|\_|\~|)/g,
      ''
    )
    .trim();
}

// Generic utilities
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const onlyNumbers = (str) => /^[0-9]+$/.test(str);

export const getProfileIdFromUrn = (entityUrn) => {
  const arr = entityUrn.split(':');
  return arr[arr.length - 1];
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const logoutFromApp = () => {
  return new Promise((resolve) => {
    // chrome.runtime.sendMessage({ action: 'logout' }, (resp) => {
    //   return resolve(resp.status === 'success');
    // });
  });
}