import React, { useState } from "react";
import { Text, Button, Pane } from "evergreen-ui";

import logo from "../../assets/img/icon.svg";
import { apiEndPoint } from "../../utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function NotLoggedInLinkedIn() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let intv;
    const handleMessage = (event) => {
      try {
        // Check if the message received is the correct one
        if (event.data.type === "csrfTokenResponse" && event.data.csrfToken) {
          clearInterval(intv);

          setError(false);
          navigate("/welcome"); // Navigate to the welcome page
        } else if (
          event.data.type === "csrfTokenResponse" &&
          event.data.error
        ) {
          clearInterval(intv);
          setError(true); // If there's an error, set error to true
        }
      } catch (error) {
        clearInterval(intv);
        setError(true); // If any error occurs, assume extension is unavailable
      }
    };

    window.onload = () => window.postMessage({ action: "getCsrfToken" }, "*");

    // Set interval to send a message every second
    intv = setInterval(() => {
      window.postMessage({ action: "getCsrfToken" }, "*");
    }, 5000);

    // Listen for messages from the extension
    window.addEventListener("message", handleMessage);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [navigate]);

  return (
    <Pane
      width="100vw"
      height="100vh"
      display="grid"
      gridTemplateColumns="500px"
      placeContent="center"
      textAlign="center"
      justifyItems="center"
    >
      <img style={{ width: 80, marginBottom: 20 }} src={logo} alt="logo" />
      <Text size={600}>
        It appears you are not logged into LinkedIn on this browser. Please log
        into your account to use this extension.
      </Text>

      <a
        target="_blank"
        href="https://www.linkedin.com/"
        style={{ marginTop: 20, marginBottom: 20, textDecoration: "inherit" }}
      >
        <Button appearance="primary">Go to LinkedIn</Button>
      </a>

      <Text size={500} marginBottom={8}>
        After installing the extension, please reload the page.
      </Text>
    </Pane>
  );
}

export default NotLoggedInLinkedIn;
