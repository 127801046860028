import React, { useEffect, useState } from "react";
import { Pane, Button, Text, TextInputField, toaster } from "evergreen-ui";
import axios from "axios";
import "./Profile.css";
import { readStorage, apiEndPoint } from "../../utils";
import { useNavigate } from "react-router-dom";
function Profile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [myProfileData, setMyProfileData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  function handleChange(e) {
    const { name, value } = e.target;
    setMyProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
 async function handleResetPassword() {
    //setLoading(true);
    if (
      !myProfileData.currentPassword ||
      !myProfileData.newPassword ||
      !myProfileData.confirmPassword
    ) {
      toaster.danger("All fields are required!", { duration: 6 });
      return;
    }
    if (myProfileData.newPassword !== myProfileData.confirmPassword) {
      toaster.danger("Passwords do not match!", { duration: 6 });
      return;
    }
    setLoading(true);
    try {
      let token = localStorage.getItem("jwt");
      const { data } = await axios.patch(
        apiEndPoint + `/auth/update-profile`,
        myProfileData,
        { headers: { authorization: "Bearer " + token } }
      );
      setLoading(false);
      if (data.success) {
        toaster.success("Your password has been updated!", { duration: 6 });
        return;
      }

      toaster.danger(data.message, { duration: 6 });
    } catch (e) {
      if (e.response) {
        toaster.danger(e.response.data.message);
      }
      setLoading(false);
    }
  }
  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <Pane
      display="grid"
      justifyItems="center"
      textAlign="center"
      gridGap={20}
      placeContent="center"
      height="70vh"
    >
      <Text size={600} width={600}></Text>
      <Pane width="60%" display="block" textAlign="left">
        <h2 className="heading">My Profile</h2>
        <TextInputField
          label="Current Password"
          name="currentPassword"
          value={myProfileData.currentPassword}
          placeholder=""
          type="password"
          onChange={handleChange}
        />
        <TextInputField
          label="New Password"
          name="newPassword"
          value={myProfileData.newPassword}
          type="password"
          placeholder=""
          onChange={handleChange}
        />
        <TextInputField
          label="Confirm Password"
          name="confirmPassword"
          value={myProfileData.confirmPassword}
          type="password"
          placeholder=""
          onChange={handleChange}
        />
        <Button
          appearance="default"
          position="relative"
          left="60%"
          size="large"
          transform="translateX(-50%)"
          onClick={() => navigate("/notifications")}
        >
          Cancel
        </Button>
        <Button
          appearance="primary"
          position="relative"
          left="64%"
          size="large"
          transform="translateX(-50%)"
          onClick={handleResetPassword}
          isLoading={loading}
        >
          Update
        </Button>
      </Pane>
    </Pane>
  );
}
export default Profile;
