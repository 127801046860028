import React from "react";
import { SelectField, Pagination as PaginationUiComponent } from "evergreen-ui";

function Pagination({
  pagination,
  setPagination,
  totalResults,
  handleDownload,
}) {
  /**
   * @desc Sets current page in pagination state
   * @param {Object} [e] The event object in js click
   */

  const handlePage = (name) => {
    // const { name } = e.target;
    if (name === "next") {
      setPagination((prev) => ({
        ...prev,
        currentPage: prev.currentPage + 1,
      }));
    } else {
      setPagination((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
      }));
    }
  };

  // -----

  const handleDirectPageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
    }));
  };

  // -----

  /**
   * @desc Sets the count value in pagination state
   * @param {Object} [e] The event object in js click
   */

  const handleCountChange = (e) => {
    const { value } = e.target;
    setPagination((prev) => ({
      ...prev,
      count: value,
    }));
  };

  // -----

  /**
   * @desc Creates a readable data of the current pagination state
   * @returns {String} "start count - end count / totalResults"
   */
  function getPaginationDetails() {
    const startCount = (pagination.currentPage - 1) * pagination.count + 1;
    const endCount =
      startCount + pagination.count > totalResults
        ? totalResults
        : startCount + pagination.count;
    return `${startCount} - ${endCount} / ${totalResults}`;
  }

  return (
    <div>
      <div
        style={{
          // display: 'flex',
          // gridTemplateColumns: 'auto 150px',
          // alignItems: 'end',

          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PaginationUiComponent
          page={pagination.currentPage}
          totalPages={Math.ceil(totalResults / pagination.count)}
          onNextPage={() => handlePage("next")}
          onPreviousPage={() => handlePage("prev")}
          onPageChange={handleDirectPageChange}
          style={{ marginTop: "6px" }}
        ></PaginationUiComponent>
        <SelectField
          name="countPerPage"
          id="count-per-page"
          label=""
          width={70}
          value={pagination.count}
          onChange={handleCountChange}
          marginBottom={0}
        >
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={75}>75</option>
          <option value={100}>100</option>
        </SelectField>
      </div>
    </div>
  );
}

export default Pagination;
