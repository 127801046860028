import React, { useState, useEffect } from 'react';
import { Text } from 'evergreen-ui';
import icon from '../assets/img/icon.svg';
import './Loading.css';

function Loading({ type }) {
  const [showText, setShowText] = useState(false);
  const [text, setText] = useState(0);

  useEffect(() => {
    if (type === 'init') {
      const timer1 = setTimeout(() => setShowText(true), 3000);
      const timer2 = setTimeout(() => setText(1), 25000);
      const timer3 = setTimeout(() => setText(2), 75000);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
      };
    }
  }, []);

  return (
    <div className="loadingContainer">
      <div className="loadingIcon">
        <img src={icon} alt="loading" />
      </div>
      {showText && (
        <Text maxWidth={500} color="muted" size={600} marginTop={50}>
          {text === 0 &&
            'This may take some time.\nPlease bear with us as we collect and organize all your notifications.'}
          {text === 1 &&
            'It appears you have a lot of notifications!\n\nThe initial collection always takes longer so hang tight.'}
          {text === 2 &&
            "We're almost there.\n\nRemember ... this only happens once."}
        </Text>
      )}
    </div>
  );
}

export default Loading;
