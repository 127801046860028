import React, { useEffect } from 'react';
import { SelectField, option } from 'evergreen-ui';
import { readStorage } from '../../../../utils';

function Sorting({ sorting, setSorting, dbSortKey, sortList }) {
  /**
   * @desc Sets the sorting state
   * @param {Object} [e] The event object in js click
   */

  const handleSorting = (e) => {
    const { value } = e.target;
    localStorage.setItem(dbSortKey, value);
    setSorting(value);
  };
  useEffect(() => {
    //setSorting(localStorage.getItem(dbSortKey));
  }, []);
  // -----
  return (
    <div>
      <div className="sorting">
        <SelectField
          data-tour="step-6"
          label=""
          width={120}
          value={sorting}
          name={'sorting'}
          onChange={handleSorting}
          marginBottom={0}
        >
          {(sortList || []).map((x) => {
            return (
              <option key={x.value} value={x.value}>
                {x.label}
              </option>
            );
          })}
        </SelectField>
      </div>
    </div>
  );
}

export default Sorting;
