import React, { useState } from 'react';
import {
  Button,
  Text,
  Pane,
  TextInputField,
  Strong,
  toaster,
} from 'evergreen-ui';
import axios from 'axios';
import { apiEndPoint } from '../../utils';
function ForgotPassword({ setFormType, setUserLoggedIn }) {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: '',
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await axios.patch(
        apiEndPoint + '/auth/forgot-password-link',
        credentials
      );
      setLoading(false);
      if (data.success) {
        toaster.success('Reset password link has been sent to your email!');
        setFormType('login');
        return;
      }
      return toaster.danger(data.message);
    } catch (e) {
      setLoading(false);
      if (e.response) {
        toaster.danger(e.response.data.message);
      }
    }
  };

  return (
    <Pane textAlign="left" display="block" width="100%" padding="10px">
      <Pane textAlign="center" marginBottom={20}>
        <Strong>Forgot Password</Strong>
      </Pane>
      <TextInputField
        label="Email"
        placeholder="Enter your email"
        onChange={handleChange}
        name="email"
      />
      <Button
        appearance="primary"
        position="relative"
        left="50%"
        transform="translateX(-50%)"
        onClick={handleSubmit}
        isLoading={loading}
      >
        Reset Password
      </Button>
      <Pane
        marginTop={10}
        cursor="pointer"
        onClick={() => setFormType('login')}
      >
        <center>
          <Text>Go to Login</Text>
        </center>
      </Pane>
    </Pane>
  );
}

export default ForgotPassword;
