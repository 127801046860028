import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Login from "./Dashboard/Login";
import Loading from "../components/Loading";

const Home = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("jwt");
  const userId = localStorage.getItem("userId");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  // Check authentication status
  const checkAuth = () => {
    if (token && userId) {
      // If user is authenticated, redirect based on subscription status
      if (user?.subscriptionStatus === "active") {
        navigate("/welcome");
      } else if (
        user?.subscriptionStatus === "trial" ||
        user?.subscriptionStatus === "inactive" ||
        user?.subscriptionStatus === "canceled"
      ) {
        navigate("/package");
      } else {
        // Default fallback if subscription status is undefined
        navigate("/welcome");
      }
      return;
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);
  if (token) {
    // Return loading component while checking
    return <Loading />;
  } else {
    // If not authenticated, show login
    return <Login />;
  }
};

export default Home;
