import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Pane,
  Heading,
  Text,
  Tooltip,
  Popover,
  Menu,
  RefreshIcon,
  LogOutIcon,
  BuildIcon,
  CrossIcon,
  Position,
  toaster,
  Button,
  PersonIcon,
  HelpIcon,
  Tab,
  Switch,
  Dialog,
} from "evergreen-ui";
import { useLocation } from "react-router-dom";
import "./Header.css";
import logo from "../assets/img/icon.svg";
import Cookies from "js-cookie";
import { apiEndPoint, formatTimeStamp } from "../utils";
import { useNavigate } from "react-router-dom";

function Header({
  setGlobalSettings,
  globalSettings,
  user,
  retNotifications,
  setUserLoggedIn,
  selectedTab,
  setSelectedTab,
  gotoProfilePage,
  refreshConacts,
  setSearchValue,
}) {
  const location = useLocation();
  const [isTrialActive, setIsTrialActive] = useState(false);
  const [users, setUsers] = useState(null);
  selectedTab = location.pathname.split("/").slice(1);
  let token = localStorage.getItem("jwt");
  let userId = localStorage.getItem("userId");
  const [syncDatas, setSyncData] = useState(false);
  let linkedInUser = {};
  const [userDatas, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [hasResponse, setHasResponse] = useState(false); // Tracks if a valid response is received
  const maxAttempts = 3;
  const retryDelay = 1000; // Delay between retries in milliseconds
  let retryTimer;

  const checkExtensionStatus = useCallback(
    (attempt = 1) => {
      if (attempt > maxAttempts || hasResponse) {
        if (!hasResponse) navigate("/lime-instructions");
        return;
      }
      window.onload = () => {
        window.postMessage({ action: "testExtensionInstalled" }, "*");
        window.postMessage({ action: "getCsrfToken" }, "*");
      };
      window.postMessage({ action: "testExtensionInstalled" }, "*");
      window.postMessage({ action: "getCsrfToken" }, "*");

      // Schedule a retry if no response is received
      retryTimer = setTimeout(() => {
        if (!hasResponse) checkExtensionStatus(attempt + 1);
      }, retryDelay);
    },
    [hasResponse, navigate]
  );

  useEffect(() => {
    const handleExtensionMessages = (event) => {
      if (!event.data || !event.data.type) return;
      switch (event.data.type) {
        case "extensionInstalledResponse":
          setHasResponse(true);
          clearTimeout(retryTimer);
          break;
        case "csrfTokenResponse":
          const { csrfToken } = event.data;
          setHasResponse(true);
          clearTimeout(retryTimer);
          if (!csrfToken) navigate("/not-login-linkedIn");
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", handleExtensionMessages);
    checkExtensionStatus();
    return () => {
      window.removeEventListener("message", handleExtensionMessages);
      clearTimeout(retryTimer);
    };
  }, [checkExtensionStatus, navigate]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUserData(userData);
      // Check if the user is on a trial and has remaining days
      if (
        userData?.subscriptionStatus == "trial" &&
        userData?.remainingTrialDays == 0
      ) {
        setIsTrialActive(
          userData.subscriptionStatus === "trial" &&
            userData.remainingTrialDays == 0
        );
      } else if (userData?.subscriptionStatus == "canceled") {
        setIsTrialActive(userData?.subscriptionStatus == "canceled");
      } else if (userData?.subscriptionStatus == "inactive") {
        setIsTrialActive(userData?.subscriptionStatus == "inactive");
      }
    }
  }, []);

  const updateLastSync = async () => {
    try {
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        liuser: userId,
        authorization: "Bearer " + token,
      };
      let req = {
        method: "PATCH",
        body: null,
        headers: new Headers(headers),
      };
      const res = await fetch(
        apiEndPoint + "/notifications/update_last_sync",
        req
      );
      let result = await res.json();
      if (result && result?.user) {
        setUsers(result?.user);
        localStorage.setItem("profile", JSON.stringify(result?.user));
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (location.pathname === "/package") {
      syncData();
    } else {
      updateLastSync();
    }
  }, []);

  // Function to request LinkedIn user data
  const fetchLinkedInUserData = async () => {
    window.postMessage({ action: "getLoggedInUser" }, "*");
    window.onload = () => {
      window.postMessage({ action: "getLoggedInUser" }, "*");
    };
  };

  function categorizeData(data) {
    const birthdays = [];
    const jobChanges = [];
    const workAnniversaries = [];

    data?.forEach((item) => {
      if (item.type === "BIRTHDAY_PROP") {
        birthdays.push(item);
      } else if (item.type === "JOB_CHANGE_PROP") {
        jobChanges.push(item);
      } else if (item.type === "WORK_ANNIVERSARY_PROP") {
        workAnniversaries.push(item);
      }
    });

    return {
      birthdays,
      jobChanges,
      workAnniversaries,
    };
  }

  const SyncDataFuntion = async () => {
    let loggedInUser = localStorage.getItem("linkedInUser") || null;
    loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;

    loggedInUser && (await fetchLinkedInUserData());
    const handleMessage = (event) => {
      if (event.data && event.data.type === "loggedInUserResponse") {
        if (event.data.userData) {
          getLoggedInUser(event.data.userData);
          setError(null);
        } else {
          setError(event.data.error || "An unknown error occurred");
        }
      } else if (event.data.type === "getNotificationsResponse") {
        // Change from action to type
        if (event.data.data) {
          let initialMode = true,
            typeIndex = 0,
            count = 10,
            notificationTypes = {
              job_changes: {
                key: "job_changes",
                type: "JOB_CHANGE_PROP",
                start: 0,
                count,
                isInitalDone: false,
                isDone: false,
              },
              work_anniversaries: {
                key: "work_anniversaries",
                type: "WORK_ANNIVERSARY_PROP",
                start: 0,
                count,
                isInitalDone: false,
                isDone: false,
              },
              birthdays: {
                key: "birthdays",
                type: "BIRTHDAY_PROP",
                start: 0,
                count,
                isInitalDone: false,
                isDone: false,
              },
            };
          // notificationKeys = Object.keys(notificationTypes),
          let results = categorizeData(event.data.data[1]);
          new Promise(async (done) => {
            let token = localStorage.getItem("jwt");

            let response = await callApi(
              "/notifications/check-cards",
              { body: JSON.stringify(results), method: "POST" },
              { liuser: loggedInUser?._id, authorization: "Bearer " + token }
            );
            if (response.data) {
              for (let [key, val] of Object.entries(response.data)) {
                if (val.allMatched) {
                  // notificationTypes[key].isDone = true;
                  results[key] = [];
                  continue;
                }
                if (val.newRecords.length) {
                  let dataSet = [];
                  for (let urn of val.newRecords) {
                    let result = results[key].find((x) => x.entityUrn === urn);
                    if (result) dataSet.push(result);
                  }
                  results[key] = dataSet;
                }
              }
            }

            loggedInUser &&
              (await saveNotifications(
                token,
                loggedInUser?._id,
                event.data.data[1]
              ));
            await updateLastSync();
            return done(results);
          });
        } else if (event.data.error) {
          setError(event.data.error);
        }
      }
    };
    window.onload = () => {
      window.postMessage({ action: "getNotifications" }, "*");
    };
    window.postMessage({ action: "getNotifications" }, "*");
    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);
  };

  const saveNotifications = async (token, _id, requestData) => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      liuser: _id,
      authorization: "Bearer " + token,
    };

    let req = {
      method: "POST",
      body: JSON.stringify(requestData),
      headers: new Headers(headers),
    };
    const res = await fetch(apiEndPoint + "/notifications/add", req).catch(
      (error) => {}
    );
    await res.json();
  };
  // Request notifications from the extension

  const getLoggedInUser = async (resp) => {
    try {
      const result = {
        firstName: resp.firstName,
        lastName: resp.lastName,
        country: resp.geoCountryName,
        headline: resp.headline,
        summary: resp.summary,
        entityUrn: resp.entityUrn,
        industryName: resp.industryName,
        profileId:
          resp.entityUrn &&
          (resp.entityUrn || "")
            .replace("urn:li:fsd_profile:", "")
            .replace("urn:li:fs_profile:", ""),
        location: resp.geoLocationName,
        publicIdentifier: resp.miniProfile && resp.miniProfile.publicIdentifier,
        memberId:
          resp &&
          resp.miniProfile &&
          (resp.miniProfile.objectUrn || "").replace("urn:li:member:", ""),
        profilePicture:
          resp.miniProfile &&
          resp.miniProfile.picture &&
          resp.miniProfile.picture["com.linkedin.common.VectorImage"] &&
          resp.miniProfile.picture["com.linkedin.common.VectorImage"].rootUrl +
            resp.miniProfile.picture["com.linkedin.common.VectorImage"]
              .artifacts[2].fileIdentifyingUrlPathSegment,
      };
      let token = localStorage.getItem("jwt");

      let resultRes = await callApi(
        "/notifications/init",
        {
          method: "POST",
          body: JSON.stringify({ userDetails: result }),
        },
        {},
        token
      );
      if (resultRes.user) {
        linkedInUser = resultRes.user;
      }

      linkedInUser = { ...linkedInUser, ...result };
      localStorage.setItem("linkedInUser", JSON.stringify(linkedInUser));
      Cookies.set("linkedInUser", linkedInUser?._id);

      setCount(resultRes.user?._id);
      return linkedInUser;
    } catch (e) {}
  };
  function callApi(
    api,
    data = { method: "POST" },
    reqHeaders = {},
    token = false
  ) {
    return new Promise(async (resolve, reject) => {
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...reqHeaders,
      };
      if (token) {
        headers.authorization = "Bearer " + token;
      }
      let req = {
        ...data,
        headers: new Headers(headers),
      };
      const res = await fetch(apiEndPoint + api, req).catch((error) =>
        reject(error)
      );
      return resolve(await res.json());
    });
  }
  async function setCount(_id) {
    try {
      let token = localStorage.getItem("jwt");
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        liuser: _id,
        authorization: "Bearer " + token,
      };
      let req = {
        method: "GET",
        headers: new Headers(headers),
      };
      const res = await fetch(apiEndPoint + "/notifications/count", req).catch(
        (error) => {}
      );
      let result = await res.json();

      return result;
    } catch (error) {
      return false;
    }
  }
  async function syncData() {
    await SyncDataFuntion();
    setIsRotating(true);
    // Simulate a 3-second sync process
    setTimeout(() => {
      setIsRotating(false);
    }, 3000);
  }
  function setTab(tab) {
    setSearchValue("");
    localStorage.setItem("selectTab", tab);
    setSelectedTab(tab);
  }
  function setToggle(e) {
    setTogglePause(e.target.checked);
  }
  const { fullName, profilePicture, lastSync } = users || "";
  const [togglePause, setTogglePause] = useState(true);
  const [isRotating, setIsRotating] = useState(false);
  const tabs = [
    { name: "Feeds", syncTitle: "Check for New Feeds" },
    { name: "Notifications", syncTitle: "Check for New Notifications" },
    { name: "Watch-List", syncTitle: "Check for New Notifications" },
    { name: "Settings", syncTitle: "Check for New Notifications" },
    { name: "Welcome", syncTitle: "Check for New Notifications" },
  ];

  function HelpDialog() {
    const [isShown, setIsShown] = useState(false);
    return (
      <Pane>
        <Dialog
          isShown={isShown}
          hasHeader={false}
          title="Help"
          onCloseComplete={() => setIsShown(false)}
          confirmLabel="Got it!"
        >
          <div className="helphead">
            <h1>Help</h1>
          </div>
          <div className="helpmsg">
            <p>We suggest you bookmark this page or pin the extension.</p>
            <p>
              To pin the extension, click the puzzle piece in the top right
              corner.
            </p>
            <p>Then click the pin next to the LiMe icon.</p>
            <p></p>
            <p>To bookmark, simply grab the puzzle piece next to the</p>
            <p>address bar and drag it down to your bookmark bar.</p>

            <p>
              If you have any other issues, feel free to reach out to us at
              help@getlimeapp.com
            </p>
          </div>
        </Dialog>

        <Button iconBefore={HelpIcon} onClick={() => setIsShown(true)}>
          Help
        </Button>
      </Pane>
    );
  }

  async function cancelSubscription() {
    let user = JSON.parse(localStorage?.getItem("user"));
    const userId = user._id;
    try {
      const response = await fetch(apiEndPoint + "/auth/cancel-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        body: JSON.stringify({ userId }), // Pass the userId in the request body
      });

      const result = await response.json();
      if (response.ok) {
        user.subscriptionStatus = "canceled";
        localStorage?.setItem("user", JSON.stringify(user));
        setIsTrialActive(true);
        toaster.success("Subscription canceled successfully!");
        window.location.href = "/package";
      } else {
        toaster.success(result.error);
      }
    } catch (error) {
      toaster.success("Error canceling subscription ", error?.message);
    }
  }
  async function resumeSubscription() {
    let user = JSON.parse(localStorage?.getItem("user"));
    const userId = user._id;
    try {
      const response = await fetch(apiEndPoint + "/auth/resume-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        body: JSON.stringify({ userId }), // Pass the userId in the request body
      });

      const result = await response.json();
      if (response.ok) {
        user.subscriptionStatus = "active";
        localStorage?.setItem("user", JSON.stringify(user));
        setIsTrialActive(false);
        toaster.success("Subscription Resume successfully!");
        window.location.href = "/welcome";
      } else {
        toaster.success(result.error);
      }
    } catch (error) {
      toaster.success("Error Resume subscription ", error?.message);
    }
  }

  return (
    <header>
      <Pane
        display="flex"
        padding={10}
        paddingX={30}
        background="#fff"
        boxShadow="0px 4px 20px rgba(0,0,0,0.06)"
        className="header-container"
      >
        <Pane flex={1} alignItems="center" display="flex">
          <img style={{ width: 40, marginRight: 10 }} src={logo} alt="logo" />
          <Heading is="h3" marginRight={50} fontWeight="bold">
            LiMe
          </Heading>
          {tabs.map((tab, index) => (
            <Tab
              data-tour={`step-${index + 1}`}
              key={index}
              id={tab.name}
              onSelect={() => navigate("/" + tab.name.toLocaleLowerCase())}
              isSelected={selectedTab[0] === tab.name.toLocaleLowerCase()}
              disabled={isTrialActive == true}
            >
              {tab.name}
            </Tab>
          ))}
        </Pane>
        {globalSettings?.showGlobalAlert ? (
          <Pane flex={1} alignItems="center" display="flex">
            <Text className="utter-notification">
              {globalSettings?.globalAlert}
            </Text>
          </Pane>
        ) : (
          ""
        )}

        <Pane
          display="grid"
          gridTemplateColumns="1fr auto"
          gridGap={10}
          alignItems="center"
        >
          <Pane
            display="grid"
            gridTemplateColumns="auto auto auto"
            gridGap={30}
            alignItems="center"
          >
            <Pane style={{ display: "flex" }}>
              <Tooltip
                content={
                  tabs.find((x) => x.name.toLocaleLowerCase() == selectedTab[0])
                    ?.syncTitle
                }
              >
                <RefreshIcon
                  id="refreshIcon"
                  data-tour="step-4"
                  color="success"
                  marginLeft={10}
                  marginTop={7}
                  onClick={!isTrialActive ? syncData : undefined} // Disable onClick if trial is active
                  cursor={isTrialActive ? "not-allowed" : "pointer"} // Update cursor based on trial state
                  marginRight={10}
                  size={20}
                  className={isRotating ? "rotate" : ""}
                  style={{ opacity: isTrialActive ? 0.5 : 1 }} // Visually indicate disabled state
                />
              </Tooltip>
              <div>
                <Text whiteSpace="nowrap" size={300}>
                  Last Sync
                </Text>
                <br />

                <Text whiteSpace="nowrap" size={300} fontWeight="bold">
                  {lastSync == "" || lastSync == undefined
                    ? "N/A"
                    : `${formatTimeStamp(new Date(lastSync)).date} ${
                        formatTimeStamp(new Date(lastSync)).meridiem
                      }`}
                </Text>
              </div>
            </Pane>

            <Pane style={{ display: "flex" }}>
              <HelpDialog />
            </Pane>
          </Pane>
          <Tooltip content={fullName}>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={({ close }) => (
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      onSelect={() => {
                        navigate("/profile");
                      }}
                      icon={PersonIcon}
                    >
                      My Profile
                    </Menu.Item>
                    <Menu.Item icon={BuildIcon}>v 2.0.1</Menu.Item>
                    {isTrialActive == false &&
                      userDatas?.subscriptionStatus == "active" && (
                        <Menu.Item
                          icon={CrossIcon}
                          onSelect={cancelSubscription}
                        >
                          Cancellled SUBSCRIPTION
                        </Menu.Item>
                      )}

                    {userDatas?.subscriptionStatus == "canceled" && (
                      <Menu.Item
                        icon={RefreshIcon}
                        onSelect={resumeSubscription}
                      >
                        Resume SUBSCRIPTION
                      </Menu.Item>
                    )}
                    <Menu.Item
                      onSelect={() => {
                        localStorage.removeItem("userId");
                        localStorage.removeItem("jwt");
                        navigate("/login"); // Use navigate to redirect
                      }}
                      icon={LogOutIcon}
                    >
                      Logout
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              )}
            >
              <Avatar size={40} src={profilePicture} cursor="pointer">
                {fullName}
              </Avatar>
            </Popover>
          </Tooltip>
        </Pane>
      </Pane>
    </header>
  );
}

export default Header;
