// src/config/environment.js

/**
 * Environment configuration with proper fallbacks and validation
 */
const getEnvironmentConfig = () => {
  // Validate required environment variables
  const requiredVars = ['REACT_APP_API_ENDPOINT'];
  const missingVars = requiredVars.filter(varName => !process.env[varName]);
  
  if (missingVars.length > 0) {
    console.warn(`Missing required environment variables: ${missingVars.join(', ')}`);
  }

  // Configuration for different environments
  const config = {
    development: {
      apiEndpoint: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8009',
      stripePublishableKey: process.env.REACT_APP_STRIPE_TEST_KEY,
      environment: 'development'
    },
    production: {
      apiEndpoint: process.env.REACT_APP_API_ENDPOINT || 'https://app-server.getlimeapp.com',
      stripePublishableKey: process.env.REACT_APP_STRIPE_LIVE_KEY,
      environment: 'production'
    }
  };

  return config[process.env.NODE_ENV || 'development'];
};

// Create the configuration
export const config = getEnvironmentConfig();

// Export individual values
export const apiEndpoint = config.apiEndpoint;
export const stripePublishableKey = config.stripePublishableKey;

// Also export as default
export default config;